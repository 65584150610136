@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Rajdhani&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Rajdhani", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.header {
  width: 75%;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  height: 80px;
  background-color: #dfebdf;
}

.threejs {
  padding: 0;
  overflow: visible;
  width: 10%;
  /* border: 1px #0083cf solid; */
  position: relative;
}

.second {
  /* width: 80%; */
  background-color: lightblue;
  /* flex-grow: 1; */
}

nav div a::after {
  content: "";
  box-sizing: border-box;
  display: block;
  width: 0;
  height: 2px;
  /* background: rgb(148 163 184); */
  background: #f00;
  transition: width 0.4s;
}

nav div a:hover::after {
  width: 100%;
  transition: width 0.4s;
}
